(function($) {

$(window).bind("load resize", function() {
  
  var navbarHeight = $(".navbar").outerHeight();
  var headerTopHeight = $(".header-top-first").outerHeight();

  if ( $(window).width() < 768 ) {
	  $(".front-page .navbar").css("top", 0 );
	  $(".front-page .masthead").css("padding-top", navbarHeight );
	}

	else {
	  $(".front-page .navbar").css("top", headerTopHeight);
	  $(".front-page .masthead").css("padding-top", headerTopHeight + navbarHeight );
	}

});

})( jQuery );